<template>
  <div class="screenContainer">
    <img src="../../assets/zhibobacknew.png" class="backImg" />
    <div class="screenContent">
      <div class="screenLeft">
        <div id="main"></div>
      </div>
      <div class="screenRight">
        <div class="chatBoard">
          <div
            v-for="(item, index) in messageContainer"
            :key="index"
            class="messageItem"
          >
            <div class="messageUser">
              {{ userNamesMap[item.userId]["name"] }}：<span
                class="messageContent"
                >{{ item.data }}</span
              >
            </div>
          </div>
        </div>
        <div class="input">
          <el-input
            type="textarea"
            v-model="editMessage"
            placeholder="Please input the message and press enter to send it"
            resize="none"
            @keydown.enter.native="sendMessage"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as QNRTC from "pili-rtc-web";
import { createRoomToken, getUserNames } from "../../api/index";
import { getUserId, getProjectCode } from "../../utils/store";

const myRoom = new QNRTC.TrackModeSession();

export default {
  data() {
    return {
      audioDevice: "",
      videoDevice: "",
      audioDevices: [],
      videoDevices: [],
      roomToken: "",
      userList: [],
      messageContainer: [],
      editMessage: "",
      userNamesMap: {},
      roomId: this.$route.query.roomId,
    };
  },
  async mounted() {
    document.title = "Lecture";
    this.getUserNames();
    this.joinRoom();
  },
  async destroyed() {
    await myRoom.unsubscribe(
      myRoom.subscribedTracks.map((item) => item.info.trackId)
    );
    myRoom.leaveRoom();
  },
  methods: {
    getUserNames() {
      getUserNames(getProjectCode()).then((res) => {
        if (res.data.code === 0) {
          this.userNamesMap = res.data.data;
        }
      });
    },
    async joinRoom() {
      let roomToken;
      const result = await this.createRoomToken();
      if (result.code == 0) {
        roomToken = result.data.token;
      } else {
        this.$message(result.msg);
        return;
      }
      try {
        // 加入房间
        await myRoom.joinRoomWithToken(roomToken);
        this.$message({
          message: "Join the room successful",
          type: "success",
        });
        // 如果加入房间后发现房间已经有人发布，就订阅他
        this.subscribeTracks(myRoom.trackInfoList);
      } catch (e) {
        console.error(e);
        this.$message(`Join room failed！ErrorCode: ${e.code || ""}`);
        return;
      }
      myRoom.on("track-add", async (tracks) => {
        this.subscribeTracks(tracks);
      });
      myRoom.on("messages-received", (msgData) => {
        this.messageContainer = [...this.messageContainer, ...msgData];
      });
    },

    async subscribeTracks(trackInfoList) {
      let subscribedVideo = myRoom.subscribedTracks.filter(
        (item) => item.info.kind === "video"
      );

      await myRoom.unsubscribe(subscribedVideo.map((t) => t.trackId));
      let videos = document.getElementsByTagName("video");

      for (let i of videos) {
        i.remove();
      }
      let infoList = trackInfoList.filter((item) => item.tag !== "employee");
      myRoom.subscribe(infoList.map((t) => t.trackId)).then(async (tracks) => {
        await myRoom.unsubscribe(myRoom.subscribedTracks.map((t) => t.trackId));
        for (const track of tracks) {
          let main = document.getElementById("main");
          track.play(main);
        }
      });
      this.subscribedTrackInfos = [...this.subscribedTrackInfos, ...infoList];
    },
    createRoomToken() {
      return new Promise((resolve, reject) => {
        createRoomToken(this.roomId, getUserId(), "user").then((res) => {
          resolve(res.data);
        });
      });
    },
    listRoomUsers(e) {
      if (e) {
        if (this.roomId !== "") {
          listRoomUsers(this.room_id).then((res) => {
            console.log(res);
            this.userList = res.data;
          });
        }
      }
    },
    async sendMessage() {
      if (!this.editMessage) {
        this.$message.warning(
          "Please input the message and press enter to send it"
        );
        return;
      }
      try {
        await myRoom.sendCustomMessage(this.editMessage);
        let currentTime = new Date();
        let sendedMessage = {
          data: this.editMessage,
          timestamp: currentTime.getTime(),
          type: "normal",
          userId: getUserId(),
        };
        this.messageContainer.push(sendedMessage);
        this.editMessage = "";
      } catch (e) {
        this.$message.warning(`发送失败，请先加入房间`);
      }
    },
  },
};
</script>

<style lang="less">
.screenContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .backImg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .screenContent {
    width: 90%;
    height: 80%;
    display: flex;
    .screenLeft {
      width: 74%;
      #main {
        width: 100%;
        height: 100%;
        background: black;
      }
    }
    .screenRight {
      width: 26%;
      background: rgb(2, 12, 32);
      border-left: 1px solid black;
      .chatBoard {
        width: 100%;
        height: 84%;
        overflow-y: scroll;
        .messageItem {
          margin: 2vh;
          .messageUser {
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #fda600;
            font-size: 16px;
          }
          .messageContent {
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .input {
        width: 100%;
        height: 16%;
        .el-textarea {
          height: 100%;
          .el-textarea__inner {
            // background: rgba(2,12,32,0.2);
            height: 100%;
            // border: none;
            border-radius: 0;
          }
        }
        .el-textarea__inner:focus {
          outline: 0;
          border: 1px solid white;
        }
      }
    }
  }
}
.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}
</style>
